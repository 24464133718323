<template>
  <div class="right ">
    <div class="card">
      <div>
        <div class="flex">
          <span
            class="label">Assign to</span>
        </div>
      </div>
      <div class="card-body">
        <div
          v-for="(item,index) in assigneeList"
          :key="index"
          class="counter-box"
          :class="{'is-checked':item.assignee_id==assignee_id}"
          @click="handleAssignee(item.assignee_id)">
          <span
            class="counter-box__name"
            :class="`${item.alias}`">Counter {{getLetter(index)}}</span>
          <div class="flex flex-center insure-icon">
            <span
              v-for="(icon,i_index) in item.companies"
              :key="'icon_'+i_index"
              class="company-logo">
              <img
                :src="getCompanyImage(icon.id)"
              />
            </span>
          </div>
          <p>{{item.name}}</p>
        </div>
      </div>
      <div class="card-button">
        <div
          class="edit-button">
          <v-button
            class="button-theme button-theme-padding-13"
            @click="handlerNext">Assign</v-button>
          <v-button
            class="button-blue button-theme-padding-13"
            @click="handlerCancel">Cancel</v-button>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
import MixinOptions from '@/mixins/options'
import MixinRules from '@/mixins/rules'
import {mapActions, mapState} from 'vuex'
import {saveQuotationMotor} from '@api/quotation'
import {computedQuotation} from '@api/order'
import {getAssigneesList} from '@api/assgin'
import MixinFormat from '@/mixins/format'
import {getTicketList} from '@api/ticket'
export default {
  name: 'discountDetail',
  mixins: [MixinOptions,MixinRules,MixinFormat],
  props:{

  },
  data(){
    return{
      assigneeList:[],
      assignee_id:''
    }
  },
  computed: {
    getLetter() {
      return (index) => {
        // 将索引转换为字母
        return String.fromCharCode(65 + index)
      }
    },
    getCompanyImage(){
      return (id)=>{
        let arr = this.insureOptions.filter(item=>item.id==id)||[]
        return arr.length>0?arr[0].logo:''
      }
    },
  },
  watch:{

  },
  mounted() {
    this.network().getAssigneesList()
  },
  methods:{

    handlerCancel(){
      this.$router.back()
    },
    handlerNext(){
      this.$emit('next',this.assignee_id)
    },
    handleAssignee(id){
      this.assignee_id = id
    },
    network() {
      return {
        getAssigneesList: async () => {
          const { data } = await getAssigneesList()
          this.assigneeList = data
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.right {
  margin-top: 20px;
  margin-bottom: 20px;
  .card{
    background: white;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.12);
    .card-body{
      margin-top: 20px;
      margin-bottom: 20px;
      .counter-box{
        border-radius: 4px;
        border: 1px solid #003EE5;
        background: #FFF;
        padding: 16px;
        margin-bottom: 10px;
        cursor: pointer;
        &:last-child{
          margin-bottom: 0;
        }
        &:hover{
          border: 2px solid var(--racing-max, #003EE5);
          padding: 15px;
          background: var(--racing-max-hover, #DEF6FF);
        }
        &.is-checked{
          border: 2px solid var(--racing-max, #003EE5);
          padding: 15px;
          background: var(--racing-max-hover, #DEF6FF);
        }
        .counter-box__name{
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          padding: 2px 8px;
          align-items: center;
          border-radius: 4px;
          color: #FFFFFF;
          display: inline-block;
          margin-bottom: 10px;
          &.green{
            background: #137C4A;
          }
          &.purple{
            background: #6332A7;
          }
          &.orange{
            background: #E9681B;
          }
        }
        p{
          color: #2D3748;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%;
        }
      }
    }
    .card-button{
      background: #fff;
      width: 100%;
      .flex{
        margin-bottom: 12px;
      }
      .el-button{
        width: 100%;
        font-weight: bold;
      }
    }
  }

  .label {
    display: block;
    font-size: 24px;
    line-height: 36px;
    font-weight: 700;
  }
  .flex{
    align-items: center;
    .label{
      color: #000;
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
    }
  }
  .edit-button{
    .button-theme{
      width: 100%;
      margin-bottom: 20px;
      margin-left: 0;
    }
    .button-blue{
      padding: 0;
      margin-left: 0;
    }
  }
}
.insure-icon{
  padding: 10px 0px;
  span{
    margin-bottom: 0;
  }
  .company-logo{
    width: 58px;
    img{
      display: inline-block;
      width: 100%;
    }
  }
}
</style>
