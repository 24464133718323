import {deleteOrder, getOrderDetail} from '@/api/order'
import {assignCounter} from '@/api/assgin'
import dayjs from 'dayjs'

export default {
  data(){
    return{
      assignee_id:''
    }
  },
  mounted(){
    let {order_no,} = this.$route.query||{}

    this.mixinNetwork().getOrderDetail(order_no)
  },
  computed:{
    ticket_no(){
      let {serial_number,created_date} = this.quoteInformationForm||{}
      let date =  dayjs(created_date).format('DD MMM YYYY')
      return `${date} iPad ${serial_number}`
    },
    is_edit(){
      return  1
      let {order_no,isDraft} = this.$route.query||{}
      if(isDraft=='true'){
        return  1
      }
      if(!this.order_no)
        return  0
      return order_no!==this.order_no?1:0
    }
  },
  methods: {
    handleRefresh(){
      let {order_no,isDraft} = this.$route.query||{}
      if(order_no)
        this.network().getOrderDetail({order_no})
    },
    hanlderNext(id){
      this.assignee_id = id
      this.handlerValidate()
    },
    handlerDelete(){
      this.$ConfirmBox({
        title: '',
        message: 'Are you sure you want to delete this policy?',
        confirmBtn:'Yes',
        cancelBtn:'No'
      }).then(() => {
        this.mixinNetwork().deleteOrder()
      })
    },
    mixinNetwork(){
      return{
        deleteOrder:async (params)=>{
          params ={
            order_no:this.order_no
          }
          let {data} = await deleteOrder(params)
          this.setQuoteInformationForm(null)
          let {policy_id} =data||{}
          if(policy_id>0)
          {
            this.$router.replace({
              path:'/policy/info',
              query:{
                policy_id
              }
            })
          }else
            this.$router.replace('/policy/draft')
        },
        getOrderDetail: async (order_no,isDraft,productId,isRenew) => {
          let { data } = await getOrderDetail({order_no:order_no})
          let params ={
            order_no,
            ...data.data,
          }
          this.setQuoteInformationForm(params)
          this.setProductID(params.product_id)
        },
        assignCounter: async (params) => {
          if(!params.assignee_id)
            return
          let {order_no} = this.$route.query||{}
          let { data } = await assignCounter({...params,order_no})
          this.$message.success('Success')
          this.$router.replace('/ticket/list')
        },
      }
    }
  }
}
