<template>
  <div class="insured-empolyee">
    <div class="flex">
      <v-title title="Insured Employee"></v-title>
      <div>
        <v-button
          class="button-theme"
          @click="handlerAdd">
          Add Employee
        </v-button>
      </div>
    </div>
    <div class="card">
      <el-form
        ref="form"
        :rules="rules"
        :model="formData"
      >
        <div>
          <el-row
            class="row-bg flex-inline"
            :gutter="10"
            justify="space-around">
            <v-row-col
              :same="true"
              :same-number="24">
              <div class="form-item form-item-flex">
                <span class="flex-two">Occupations of Employee</span>
                <!--                <span>Indoor / Outdoor</span>-->
                <span>Number of Employees</span>
                <span>Est. Annual Earnings (HKD)</span>
                <!--                  <span></span>-->
              </div>
            </v-row-col>
            <v-row-col same>
              <v-row-col
                v-for="(item,index) in formData.insured_employee"
                :key="index"
                :same="true"
                :same-number="24">

                <div class="form-item ">
                  <el-form-item  class="flex-two">
                    <v-input
                      v-model ='item.occ_of_employee'
                    />
                  </el-form-item>

                  <!--                  <el-form-item >-->
                  <!--                    <v-select-->
                  <!--                      v-model='item.type'-->
                  <!--                      :list="insuredEmployeeTypeOption"-->
                  <!--                    />-->
                  <!--                  </el-form-item>-->
                  <el-form-item >
                    <v-input
                      v-model.number ='item.number_of_employee'
                      type="number"
                    />
                  </el-form-item>
                  <el-form-item >
                    <div class="flex">
                      <v-input
                        v-model.number ='item.est_annual_earnings'
                        type="number"
                      />
                    </div>
                    <span
                      v-if="index!==0"
                      class="el-icon-circle-close theme-blue"
                      @click="handlerDelete(index)"></span>
                  </el-form-item>
                </div>

              </v-row-col>

            </v-row-col>

            <v-row-col
              same>
              <v-row-col
                :same="true"
                :same-number="24">

                <div class="form-item ">
                  <el-form-item  class="flex-two">
                    <span>Total</span>
                  </el-form-item>

                  <!--                  <el-form-item >-->

                  <!--                  </el-form-item>-->
                  <el-form-item >
                    <v-input
                      :value="totalEmployees"
                      disabled
                    />
                  </el-form-item>
                  <el-form-item >
                    <div class="flex">
                      <v-input
                        :value="totalEarnings"
                        disabled
                      />
                    </div>

                  </el-form-item>
                </div>

              </v-row-col>

            </v-row-col>
          </el-row>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import options from '@/mixins/options'
import MixinRules from '@/mixins/rules'
export default {
  name: 'insuredEmployee',
  mixins: [options,MixinRules],
  props: {
    form: {
      type: Object,
      default: () => ({})
    },
    formDataLock: {
      type: Number,
      default: 0
    },
    carType: {
      type: Number,
      default: 0
    },
    policyOneDriver:{
      type: Boolean,
      default: false
    }
  },
  data(){
    return{
      list:{
        occ_of_employee:'',
        type:'',
        number_of_employee:'',
        est_annual_earnings:''
      },
      formData:{
        insured_employee:[{
          occ_of_employee:'',
          type:'',
          number_of_employee:'',
          est_annual_earnings:''
        }]
      }
    }
  },
  computed:{
    totalEmployees(){
      let {insured_employee=[]} = this.formData
      let total = 0
      insured_employee.forEach(item=>{
        total = Number(item.number_of_employee) +total
      })
      return total
    },
    totalEarnings(){
      let {insured_employee=[]} = this.formData
      let total = 0
      insured_employee.forEach(item=>{
        total = Number(item.est_annual_earnings) +total
      })
      return total
    }
  },
  watch: {
    // 表單鎖，外層會更新，數值變化了才可以更新 formData
    formDataLock: {
      handler(val, old = -1) {
        if (val !== old) {
          this.oldFormDataLock = old
        }
      }
    },
    form: {
      immediate: true,
      handler(val) {
        // if(this.formDataLock !== this.oldFormDataLock) {
        // this.oldFormDataLock = this.formDataLock
        let {insured_employee=[]} = val||{}
        if(insured_employee.length<=0)
          return
        this.formData.insured_employee = insured_employee
      }
    },
  },
  created(){
    this.list = JSON.parse(JSON.stringify(this.list))
  },
  methods:{
    submitForm(formName){
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$emit('getFormData', formName,this.formData)
        }
      })
    },
    handlerAdd(){
      let list = JSON.parse(JSON.stringify(this.list))
      this.formData.insured_employee.push(list)
    },
    handlerDelete(index){
      this.formData.insured_employee.splice(index,1)
    },
  }
}
</script>

<style scoped lang="scss">
.insured-empolyee {
  .card {
    padding-bottom: 40px;
  }

  .flex {
    justify-content: space-between;
    align-items: center;
  }
  span{
    flex: 1;
  }
  .el-form-item{
    flex: 2;
    margin-right: 15px;
  }

}
.flex-inline{
  .flex-aligin{
    margin: 16px 0;
    .el-col{
      padding: 0;
    }
  }
  .form-item{
    display: flex;
    margin-bottom: 16px;
    /*margin-top: 16px;*/
    align-items: center;
    span{
      flex: 1;
    }
    .el-form-item{
      flex: 2;
      margin-bottom: 0;
    }
    .el-form-item:nth-child(1){
      flex: 4;
    }
    .el-form-item:nth-child(4){
      flex: 3;
    }
    .theme-blue{
      /*margin-left: 10px;*/
      position: absolute;
      right: -20px;
      top: 30%;
    }

  }
  .hr-inline{
    margin:20px 10px;
  }
  .form-item-flex{
    .flex-two{
      flex: 4;
    }
    .flex-five{
      flex: 6;
    }
    span{
      flex:2
    }
    span:nth-child(4){
      flex:3
    }
    /*.el-input{*/
    /*  flex: 2;*/
    /*  margin-right: 15px;*/
    /*}*/
    /*.el-input:last-child{*/
    /*  flex: 3;*/
    /*  margin-right: 15px;*/
    /*}*/
  }
  .flex-aligin {
    margin: 16px 0;

    .el-col {
      padding: 0;
    }
  }
}
</style>
