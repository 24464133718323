
class OMath {
  // 四舍五入
  round(num, n = 2) {
    try {
      let number = this.number(num)
      // if (num.toString() == 'NaN') {
      //     return 0
      // }
      const init = Math.pow(10, n)
      // toPrecision() 方法可在对象的值超出指定位数时将其转换为指数计数法
      // toFixed 是为了 保留 .00
      const strValue = (Math.round((number * init).toPrecision(12)) / init).toFixed(n)
      // console.log('strValue',strValue)
      return this.stringToNumber(strValue, n)
    } catch {
      return 0
    }
  }
  stringToNumber(strNum, n) {
    const fig = Math.pow(10, n)
    return  (Math.round(strNum*100)/100).toFixed(2)
  }
  // 转数字
  number(val) {
    try {
      let number = parseFloat(val)
      if (number.toString() == 'NaN') return 0
      return number
    } catch {
      return 0
    }
  }
  // 处理金额数字 -- 逗号分割
  formatMoney(val, fig = 2, dollar = false) {
    let number = this.round(val, fig)

    let neg = false // 标记是否为负数
    // 判断是否为负数
    if (number < 0) {
      number = Math.abs(val).toFixed(2)
      neg = true
    }
    // 分割小数点
    const arr = String(number).split('.')
    let num = arr[0] // 整数部分
    let resultArr = []
    while (num.length > 0) {
      resultArr.unshift(num.slice(-3))
      num = num.slice(0, -3)
    }
    let result = resultArr.join(',')
    // 有小数
    if (arr.length > 1) {
      result = `${result}.${arr[1]}`
    }
    // if (arr.length ==1) {
    //   result = `${result}.00`
    // }
    // 负数
    if (neg) {
      result = dollar? `-$${result}`: `-${result}`
      return result
    }
    return dollar? `$${result}`: `${result}`
  }
}
  
export default new OMath()