import axios from '@/jslib/axios'

export const getAssigneesList = params => {
  return axios({
    method: 'get',
    url: '/admin/assignees',
    params
  })
}
export const assignCounter = params => {
  return axios({
    method: 'get',
    url: '/admin/assign',
    params
  })
}
