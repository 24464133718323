import OMath from '@/utils/math'
export default {
  filters: {
    value(val) {
      return val ? val : '- -'
    },
    formatMoney(val, fig = 2, dollar = false) {
      return val == '- -' ? val : OMath.formatMoney(val, fig, dollar)
    },
    formatYesNo(val) {
      return val? val: '/'
    },
    formatPct(val) {
      if(val === 0) {
        return '0%'
      }
      else if(!val) {
        return '-'
      }
      return `${val}%`
    },
    subDate(datetime) {
      const date = String(datetime)
      return date.slice(0, 10)
    },
    subTime(datetime) {
      const date = String(datetime)
      return date.slice(11, date.length)
    }
  },
  methods: {
    checkValueBool(value) {
      if(value === '' || value === null || value === undefined) {
        return false
      }
      return Boolean(value)
    },
    cssFontSize(value, width, fontSize) {
      // 計算逗號跟 $ 符號
      const length = String(value).length
      // 逗號，暫時忽略小數點
      const count = OMath.round(length/3, 0)
      // 數字的寬度 = font-size 的一半
      const fontWidth = fontSize/2*(length+count)
      const diff = width - fontWidth
      // console.log(width, fontSize, length, count, fontWidth, diff, '==')
      if(diff >= 0) {
        return `${fontSize}px`
      }
      else {
        const diffWidth = Math.abs(diff)
        const reduceSize = Math.ceil(diffWidth/fontSize)+1
        // console.log(reduceSize)
        return `${fontSize-(reduceSize*2)}px`
      }
    }
  }
}